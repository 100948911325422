import * as React from "react";

import {
  Flex,
  Box,
  Spacer,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useDisclosure,
  IconButton,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Drawer,
  Text,
} from "@chakra-ui/react";
import { Link } from "gatsby";
//import { ChevronDownIcon } from "@chakra-ui/icons";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import "./Nav.css";
import { ChevronDownIcon } from '@chakra-ui/icons'


export default function Nav() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Flex
        bg={"#02011E"}
        className="header"
        position={"fixed"}
        zIndex={"3"}
        justifyContent={{ base:"space-between", xl: "initial" }}
        ju

        alignItems={"center"}
      >
        <Box p="26">
          <Link to="/#home">
            <Heading size="lg">OSI SANTÉ FRANCE</Heading>
          </Link>
        </Box>

        <Spacer display={{ base: "none", xl: "initial" }}/>

        <Box
          display={{ base: "none", xl: "flex" }}
          p="26"
          className="menu"
        >
          <DesktopNav />
        </Box>
        <Box
          display={{ base: "initial", xl: "none" }}
          p="26"
          href="#home"
          boxShadow={"none"}
        >
          <IconButton
            variant={"unstyled"}
            className="burgerMenu"
            onClick={onOpen}
            icon={<MenuIcon sx={{color:"#fafafa"}} size={50} />}

          />
        </Box>
        <Drawer
          placement="right"
          onClose={onClose}
          isOpen={isOpen}
          size={"full"}
          height={"100%"}
          onClose={onClose}
        >
          <DrawerOverlay />
          <DrawerContent className="menuPhone" backgroundColor={"#02011E"}>
            <DrawerHeader textAlign={"center"} pb={"20px"}>
            <IconButton
            pl={"90%"}
                  variant={"unstyled"}
                  onClick={onClose}
                  className="closeMenu"
                  icon={<CloseIcon sx={{color:"#fafafa"}} size={40} />}
                />
              <Flex
                justifyContent="center"
              >
                <Link to="/#home">
                  <Heading size="lg">OSI SANTÉ FRANCE</Heading>
                </Link>
              </Flex>
            
            </DrawerHeader>
            <DrawerBody margin={"auto"} textAlign={"center"} height={"100%"}>
              <MobileNav onClick={onClose} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Flex>
    </>
  );
}

const DesktopNav = () => {
  return (
    <Box>
      {navItems.map((item) =>
        item.children ? (
          <Menu autoSelect={false}  key={item.label}>
            <MenuButton className="dropdownmenu">
              {item.label}
              <ChevronDownIcon w={7} h={7} pl={"5px"}className="iconMenuMore" />
            </MenuButton>
            <MenuList className="sousmenu">
              {item.children.map((child) => (
                <MenuItem key={child.label}>
                  <Link to={child.url}>{child.label}</Link>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        ) : (
          <Link to={item.url } key={item.label}>{item.label} </Link>
        )
      )}
    </Box>
  );
};

const MobileNav = () => {
  return (

      navItems.map((item) => item.children ? (
      <Menu autoSelect={false} key={item.label}
      >
        <MenuButton className="dropdownmenuPhone" color="#FAFAFA">
          <Text>{item.label}</Text>
          <ExpandMoreIcon />
        </MenuButton>
        <MenuList
          className="sousmenuPhone"
          backgroundColor={"#02011E"}
          ml={"5px"}
        >
          {item.children.map((child) => (
            <MenuItem display={"block"} color="#FAFAFA" key={child.label}>
              <Link to={child.url}>{child.label}</Link>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      ) : (
      <Box color="#FAFAFA" display={"block"} pt={"20px"} key={item.label}>
        <Link to={item.url}>{item.label} </Link>
      </Box>
      ) )
   
  );
};

const navItems = [
  {
    label: "Solutions",
    children: [
      {
        label: "Logiciel de dépistage organisé",
        url: "/Depistage",
      },
      {
        label: "Logiciel pour automate de lecture",
        url: "/Automate",
      },
      {
        label: "Logiciel de gestion de laboratoire d'analyse microbiologie ",
        url: "/Gestion",
      },
    ],
  },
  {
    label: "Plateforme",
    url: "/#Plateforme",
  },
  {
    label: "Notre équipe",
    url: "/#Equipe",
  },
  {
    label: "Partenaires",
    url: "/#Partenaire",
  },
  {
    label: "Les actualités",
    url: "/#Actualite",
  },
  {
    label: "Contact",
    url: "/#Contact",
  },
];

/* const Header = () => (
    <Flex
      bg={"#02011E"}
      className="header"
      position={"fixed"}
      zIndex={"3"}
      alignItems={"center"}
    >
      <Box p="26">
        <Link to="/#home">
          <Heading size="lg">OSI SANTÉ FRANCE</Heading>
        </Link>
      </Box>
      <Spacer />

      <Box className="menu" p="26" href="#home">
        <Box className="close" pr={"20px"}>
          <MaterialIcon icon="close" color="#E6854D" size={50} onClick />
        </Box>
        <Menu autoSelect={false}>
          <MenuButton className="dropdownmenu">
            Solutions
            <ChevronDownIcon />
          </MenuButton>
          <MenuList className="sousmenu">
            <MenuItem>
              <Link to="/Depistage">Logiciel de dépistage organisé</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/Automate">Logiciel pour automate de lecture</Link>
            </MenuItem>
            <MenuItem>
              <Link to="/Gestion">
                Logiciel de gestion de laboratoire d'analyse microbiologie
              </Link>
            </MenuItem>
          </MenuList>
        </Menu>
        <Link to="/#Plateforme">Plateforme OSI SANTÉ</Link>
        <Link to="/#Equipe">Notre équipe</Link>
        <Link to="/#Partenaire">Partenaires</Link>
        <Link to="/#Actualite">Les actualités</Link>
        <Link to="/#Contact">Contact</Link>
      </Box>
      <Box className="burger" pr={"20px"}>
        <MaterialIcon icon="menu" color="#E6854D" size={50} onClick />
      </Box>
    </Flex>
  );
  export default Header; */
