import React from "react";
import {
  Box,
  ListItem,
  List,
  Heading,
  Flex,
  Link,
  HStack,
  Image,
  Text,
  Center
} from "@chakra-ui/react";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import Linkedin from "../../images/linkedin.svg";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CopyrightIcon from '@mui/icons-material/Copyright';

import "./Footer.css";
function Footer() {
  let year = new Date().getFullYear();
  return (
    <Box
      className="footer"
      borderTop={"solid 1px #E6854D"}
      backgroundColor={"#02011E"}
      pt={"5%"}
      pr={"5%"}
      pl={"5%"}
      pb={"20px"}
    >
      <Flex justifyContent={{ base: "none", md: "space-around" }} flexDirection={{base: "column", md:"row" }} alignItems={{base:"center", md:"normal" }}>
        <Box className="infoContact">
          <List className="info">
            <ListItem>
              <Heading size="lg" mb={{base:"30px", md:"60px"}}>
                OSI SANTÉ FRANCE
              </Heading>
            </ListItem>
            <ListItem display={{ base: "none", md: "block" }}>
              <EmailIcon color="#E6854D" size={20} />
              info@osi-sante.fr
            </ListItem>
            <ListItem display={{ base: "none", md: "block" }}>
              <LocationOnIcon color="#E6854D" size={20} />8 rue
              des compagnons 14000 Caen
            </ListItem>
          </List>
        </Box>
        <Box>
          <List className="listFooter" display={{ base: "none", md: "initial" }}>
            <ListItem>
              <Heading as={"h3"} size="md" mb={"50px"}>
                Plan du site
              </Heading>{" "}
            </ListItem>
            <ListItem>
              <Link href="/#Solutions">Solutions</Link>
            </ListItem>
            <ListItem>
              <Link href="/#Plateforme">Plateforme OSI SANTÉ</Link>
            </ListItem>
            <ListItem>
              <Link href="/#Equipe">Notre équipe</Link>
            </ListItem>
            <ListItem>
              <Link href="/#Partenaire">Partenaires</Link>
            </ListItem>
            <ListItem>
              <Link href="/#Actualite">Les actualités</Link>
            </ListItem>
            <ListItem>
              <Link href="/#Contact">Contact</Link>
            </ListItem>
          </List>
        </Box>
        <Box>
          <List className="listFooter" >
            <ListItem>
              <Heading as={"h3"} size="md" mb={"50px"} display={{ base: "none", md: "initial" }}>
                Conditions et confidentialité
              </Heading>{" "}
            </ListItem>
            <ListItem>
              <Link href="Confidentialite">Politique de confidentialité</Link>
            </ListItem>
            
          </List>
        </Box>
        <Box display={{ base: "none", md: "initial" }}>
          <Heading as={"h3"} size="md" mb={"50px"} >
            Suivez nous !{" "}
          </Heading>{" "}
          <Link
            href="https://www.linkedin.com/company/osi-sante-france/"
            isExternal
          >
            <HStack spacing="10px" mt={"50px"}>
              <Image
                src={Linkedin}
                width={"50px"}
                height={"auto"}
                color={"#E6854D"}
              />
              <Text>Linkedin</Text>
            </HStack>
          </Link>
        </Box>
      </Flex>
      <Center><Text fontSize='xs' ><CopyrightIcon color="#FAFAFA" sx={{size:"5"}} />{year} OSI SANTÉ FRANCE
</Text></Center>
    </Box>
  );
}

export default Footer;
